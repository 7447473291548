<template>
  <div class="edu-frame">
    <edu-nav-bar title="停课通知" :show="show" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
    <van-dropdown-menu>
      <van-dropdown-item v-model="condition" :options="option1" />
    </van-dropdown-menu>
    <div class="content-frame" style="height: calc(100% - 46px - 48px - 44px)">
      <template v-for="(data, index) in datas">
        <div class="message-item" v-if="condition == 0 ? true : (condition == 1 && (data.errMsg == null || data.errMsg == '')) ? true : (condition == 2 && (data.errMsg != null && data.errMsg != '')) ? true : false " :key="'msg_' + index">
          <div class="message-Line">
            <div class="message-Title">人员学号:</div>
            <div>{{data.custCode}}</div>
          </div>
          <div class="message-Line">
            <div class="message-Title">人员姓名:</div>
            <div>{{ data.fullName }}</div>
          </div>
          <div class="message-Line">
            <div class="message-Title">创建时间</div>
            <div>{{ filterDateTime(data.createTime) }}</div>
          </div>
          <div class="message-Line">
            <div class="message-Title">接收状态:</div>
            <div v-if="data.errMsg == null || data.errMsg == ''" style="color: mediumseagreen">接收成功</div>
            <div v-else style="color: red;">接收失败</div>
          </div>
          <div v-if="data.errMsg != null" class="message-Line" style="flex-direction: column">
            <div class="message-Title">错误原因:</div>
            <div style="flex: 1;">{{ data.errMsg }}</div>
          </div>
        </div>

      </template>

    </div>
    <div>
      <van-button :loading="loading" square type="primary" block @click="exportExcel">导出</van-button>
    </div>
  </div>
</template>
<script>
import EduNavBar from "@/components/EduNavBar.vue";
import {Button, DropdownMenu, DropdownItem } from "vant";
import XEUtils from "xe-utils";
import BaomingApi from "@/api/BaomingApi";
export default {
  components: {
    EduNavBar,
    VanButton: Button,
    VanDropdownMenu: DropdownMenu,
    VanDropdownItem: DropdownItem
  },
  data() {
    return {
      show: false,
      datas: [],
      condition: 0,
      option1: [ { text: '全部消息', value: 0 },
        { text: '发送成功', value: 1 },
        { text: '发送失败', value: 2 } ],
      loading: false
    }
  },
  methods: {
    filterDateTime(date) {
      return date == null ? null : XEUtils.toDateString(XEUtils.toStringDate(date, 'yyyyMMddHHmmss'))
    },
    getMsgBak() {
      var wxMsgMasterId = this.$route.query.wxMsgMasterId
      var pageCondition = {page: 1, pageSize: 1000, total: 0, condition: { wxMsgMasterId: wxMsgMasterId, msgType: '883317220195700736' }}
      BaomingApi.getMsgBak(pageCondition).then(response => {
         this.datas = response.res.list
      })
    },
    exportExcel() {
      if (this.loading) {
        return
      }
      var wxMsgMasterId = this.$route.query.wxMsgMasterId
      var condition = { wxMsgMasterId: wxMsgMasterId, msgType: '883317220195700736' }
      this.loading = true
      BaomingApi.exportMsgBak(condition).then(response => {
        this.loading = false
        console.log(response.res)
        window.location.href = response.res
      }).catch(() => { this.loading = false })

    }
  },
  mounted() {
    this.getMsgBak()
  }
}
</script>
<style scoped>
.message-item {
  width: 92%;margin: 10px auto;padding: 5px 8px; background-color: white;
}
.message-Line {
  display: flex;
  line-height: 28px;
  font-size: 14px;
}
.message-Title {
  width: 80px;
}
</style>
